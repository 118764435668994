import React from "react"
import Layout from "../components/layout";
import PostList from "../components/postList";
import { graphql } from "gatsby"

import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
deckDeckGoHighlightElement();

export default function Home({data}) {
  return (
    <Layout>
      <PostList posts={data.allMarkdownRemark.edges} />
    </Layout>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD-MM-YYYY")
            tags
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`